import React from 'react';

import './App.css';

import SummerAbsenceForm from './workflows/SummerAbsenceForm';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isInitialized: false };
  }

  componentDidMount() {
    this.setState({ isInitialized: true });
  }

  render() {
    const { isInitialized } = this.state;
    if (!isInitialized) {
      return (<div></div>);
    }

    return (<SummerAbsenceForm />);
  }

}

export default App;
