import React from "react";
import validator from "validator";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import ProgressBar from "react-bootstrap/ProgressBar";

import OMSButton from "../components/button/OMSButton";
import LoadingIndicator from "../components/loading/LoadingIndicator";
import ErrorMessage from "../components/error/ErrorMessage";
import RestClientError from "../models/error/rest-client-error";

import "./../App.css";
import "./SummerAbsenceForm.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { DOMAIN_VPS } from "../models/constants/constants";

class SummerAbsenceForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            domainEndpoint: DOMAIN_VPS,
            isLoading: false,
            isLoadingData: false,
            isError: false,
            isSubmissionSuccessful: false,
            isLoadingPagination: false,
            isInitialAbsenceTypeSelection: false,
            validationErrors: [],
            statusMessage: "",
            absenceForm: {
                email: "",
                holdType: false,
                providerId: -1,
                providerName: "",
                selectedPersonId: -1,
                selectedServiceId: -1,
                selectedAppointments: [],
                selectedMonths: []
            },
            currentFormStage: "EMAIL",
            isLoadingState: false,
            studentList: [],
            studentSchedules: [],
            submittedAbsenceEvents: [],
            currentVisitPage: 0,
            instrumentResults: [],
            paginatedScheduleResults: [],
            maxSelectableVisitsAllowed: 0,
            submittedMonths: []
        };

        this.handleFormAndStageChange = this.handleFormAndStageChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleStateTransition = this.handleStateTransition.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadStudentSchedules(email) {
        this.setState({ 
                isLoadingState: true,
                studentSchedules: [], 
                instrumentResults: [],
                paginatedScheduleResults: [],
                isLoadingData: true 
            }, () => {
            let providerId = -1;
            let providerName = ""
            fetch(`${this.state.domainEndpoint}/pike/front/people/search?email=${email}`).then(response => {
                if (response.status !== 200) {
                    throw new RestClientError("Invalid response from email search");
                }

                return response.json();
            }).then(data => {
                providerId = data.id;
                providerName = `${data['first_name']} ${data['last_name']}`
                return fetch(`${this.state.domainEndpoint}/absence/${data.id}/schedules`);
            }).then(response => {
                if (response.status !== 200) {
                    throw new RestClientError("Invalid response from retrieving student schedules");
                }

                return response.json();
            }).then(data => {
                this.setState({ 
                    isLoadingData: false, 
                    studentSchedules: data['eventList'],
                    submittedAbsenceEvents: data['submittedEvents'],
                    submittedMonths: data['submittedHoldDates'],
                    absenceForm: { ...this.state.absenceForm, providerId: providerId, providerName: providerName }
                });
            }).catch(error => {
                console.error(error);
                this.setState({ isLoadingData: false, statusMessage: error.message });
            })
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ validationErrors: [], statusMessage: "" });

        const errors = this.validate();
        if (errors.length >= 1) {
            this.setState({ validationErrors: errors, isSubmissionSuccessful: false });
            window.scrollTo(0, 0);
            return;
        }

        this.setState({ isLoading: true });
        let absenceForm = { ...this.state.absenceForm };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(absenceForm)
        };
        
        const endpoint = `${this.state.domainEndpoint}/absence${absenceForm.holdType ? '/hold' : ''}`;
        fetch(endpoint, requestOptions).then(response => {
                response.json().then(data => {
                    if (response.status !== 200) {
                        this.setState({ isLoading: false, statusMessage: data.message });
                    } else {
                        this.setState({ isLoading: false, isSubmissionSuccessful: true });
                    }
                });
            });
    }

    validate() {
        let validationErrors = [];
        const absenceForm = this.state.absenceForm;

        if (absenceForm.email.trim() !== "") {
            if (!validator.isEmail(absenceForm.email)) {
                validationErrors.push("Email address entered is invalid.");
            }
        } else {
            validationErrors.push("Please enter an email address.");
        }

        if (!absenceForm.selectedPersonId || absenceForm.selectedPersonId === -1) {
            validationErrors.push("Please select a person to view appointments for.");
        }

        if (!absenceForm.holdType) {
            if (!absenceForm.selectedServiceId || absenceForm.selectedServiceId === -1) {
                validationErrors.push("Please select an instrument to view appointments for.");
            }

            const selectedAppointmentSize = absenceForm.selectedAppointments.length;
            if (selectedAppointmentSize === 0) {
                validationErrors.push("Please select visits to cancel.");
            }
            
            const allowableVisits = this.state.maxSelectableVisitsAllowed;
            if (selectedAppointmentSize > allowableVisits) {
                validationErrors.push(`No more than ${allowableVisits} appointment(s) may be selected.`);
            }
        } else {
            const selectedMonthSize = absenceForm.selectedMonths.length;
            if (selectedMonthSize === 0) {
                validationErrors.push("Please select months to hold.");
            }
        }

        return validationErrors;
    }

    handleFormChange(event) {
        this.setState({ absenceForm: { ...this.state.absenceForm, [event.target.name]: event.target.value } });
    }

    handleFormAndStageChange(event, state) {
        const value = (event.target.value === 'false' || event.target.value === 'true') ? 
            (event.target.value === 'true') : event.target.value;
        this.setState({ absenceForm: { ...this.state.absenceForm, [event.target.name]: value } }, () => {
            this.handleStateTransition(state);
        });
    }

    getAlertMessageVariant() {
        return this.state.isSubmissionSuccessful ? "success" : "danger"; 
    }

    getStudents() {
        let studentList = [];
        let studentSet = new Set();
        for (const visit of this.state.studentSchedules) {
            const personId = visit['personId'];
            if (!studentSet.has(personId)) {
                studentList.push({'personId': personId, 'name': visit['name']});
                studentSet.add(personId);
            }
        }
        return studentList;
    }

    getInstruments(personId) {
        const filteredVisits = this.state.studentSchedules.filter(element => element['personId'] == personId);
        let instrumentList = [];
        let instrumentSet = new Set();
        for (const visit of filteredVisits) {
            const serviceId = visit['serviceId'];
            if (!instrumentSet.has(serviceId)) {
                instrumentList.push({'serviceId': serviceId, 'name': visit['serviceName']});
                instrumentSet.add(serviceId);
            }
        }
        return instrumentList;
    }

    createPaginatedResults(maxEvents) {
        this.setState({
                isLoadingState: true,
                isLoadingPagination: true, 
                paginatedScheduleResults: [],
                absenceForm: {
                    ...this.state.absenceForm,
                    selectedAppointments: [],
                    selectedMonths: []
                },
                maxSelectableVisitsAllowed: maxEvents
            }, () => {
            let paginatedStudentSchedules = [];
            const filteredVisits = this.state.studentSchedules.filter(element => 
                element['personId'] == this.state.absenceForm.selectedPersonId && 
                element['serviceId'] == this.state.absenceForm.selectedServiceId);

            let studentSchedulePage = filteredVisits.length > 0 ? [filteredVisits[0]] : [];
            if (filteredVisits.length == 1) {
                paginatedStudentSchedules.push(studentSchedulePage);
            }

            for (let i = 1; i < filteredVisits.length; i++) {
                const entry = filteredVisits[i];
                if (i % 4 == 0) {
                    paginatedStudentSchedules.push(studentSchedulePage);
                    studentSchedulePage = [entry];
                } else {
                    studentSchedulePage.push(entry);
                }

                if (i == filteredVisits.length - 1) { 
                    paginatedStudentSchedules.push(studentSchedulePage);
                }
            }

            this.setState({
                isLoadingPagination: false, 
                isInitialAbsenceTypeSelection: true,
                paginatedScheduleResults: paginatedStudentSchedules
            });
        });
    }

    handleStateTransition(state) {
        let finalState = {currentFormStage: state, isLoadingState: false, statusMessage: ''};
        if (state == "STUDENT_SELECT") {
            const email = this.state.absenceForm.email;
            if (!email) {
                this.setState({ statusMessage: 'Please enter an email address' });
                return;
            }
            this.loadStudentSchedules(this.state.absenceForm.email);
        }
        if (state == "INSTRUMENT_SELECT") {
            this.setState({
                isLoadingState: true, 
                isInitialAbsenceTypeSelection: true,
                instrumentResults: [], 
                paginatedScheduleResults: [],
                absenceForm: {
                    ...this.state.absenceForm,
                    selectedServiceId: -1,
                    selectedAppointments: [],
                    selectedMonths: []
                }
            });

            const personId = this.state.absenceForm.selectedPersonId;
            if (!personId || personId === -1) {
                this.setState({ statusMessage: 'Please enter a person to specify summer absences for' });
                return;
            }
            finalState['instrumentResults'] = this.getInstruments(personId)
        } if (state == "ABSENCE_TYPE_SELECT") {
            this.setState({
                isLoadingState: true, 
                paginatedScheduleResults: [],
                isInitialAbsenceTypeSelection: false,
                absenceForm: {
                    ...this.state.absenceForm,
                    selectedAppointments: [],
                    selectedMonths: []
                }
            });
        } if (state == "APPOINTMENT_SELECT") {
            const serviceId = this.state.absenceForm.selectedServiceId;
            if (!serviceId || serviceId === -1) {
                this.setState({ statusMessage: 'Please specify an instrument.' });
                return;
            }
            const maxEvents = this.getMaxEventOccurrences();
            if (maxEvents <= 0) {
                this.setState({ 
                    statusMessage: 'You have already specified the max amount of dates for this service.',
                    maxSelectableVisitsAllowed: maxEvents
                });
                return;
            }

            this.createPaginatedResults(maxEvents);
        } if (state == "MONTH_SELECT") {
            if (this.getHoldsBySelectedPersonId().length >= 3) {
                this.setState({ 
                    statusMessage: 'This student has already submitted holds for all three months.'
                });
                return;
            }
            finalState['isInitialAbsenceTypeSelection'] = true;
        }
        this.setState(finalState);
    }

    getMaxEventOccurrences() {
        const { absenceForm, studentSchedules, submittedAbsenceEvents } = this.state;
        const { selectedPersonId, selectedServiceId } = absenceForm;

        const filteredSchedules = studentSchedules.filter(element => 
            element['personId'] == selectedPersonId && element['serviceId'] == selectedServiceId);

        const eventId = filteredSchedules[0]['eventId'];
        const eventCount = submittedAbsenceEvents.filter(event => event['eventId'] == eventId).length;

        return 4 - eventCount;
    }

    setPageNumber(number) {
        this.setState({currentVisitPage: number});
    }

    isEventSelected(eventOccurrenceId) {
        return this.state.absenceForm.selectedAppointments.map(element => element['eventOccurrenceId'])
            .includes(eventOccurrenceId);
    }

    isMonthSelected(month) {
        return this.state.absenceForm.selectedMonths.includes(month) || 
                this.includesStudentHolds(month);
    }

    addOrRemoveSelectedEvent(visit) {
        let currentVisits = this.state.absenceForm.selectedAppointments;
        const foundVisit = currentVisits.find((element) => element['eventOccurrenceId'] == visit['eventOccurrenceId']);
        if (foundVisit) {
            currentVisits.splice(currentVisits.indexOf(foundVisit), 1);
        } else {
            if (currentVisits.length >= this.state.maxSelectableVisitsAllowed) {
                return;
            }
            currentVisits.push(visit);
        }

        this.setState({ absenceForm: { ...this.state.absenceForm, selectedAppointments: currentVisits } });
    }

    addOrRemoveSelectedMonth(month) {
        let currentMonths = this.state.absenceForm.selectedMonths;
        const foundMonth = currentMonths.find(monthElement => month == monthElement);
        if (foundMonth) {
            currentMonths.splice(currentMonths.indexOf(foundMonth), 1);
        } else {
            currentMonths.push(month);
        }

        this.setState({ absenceForm: { ...this.state.absenceForm, selectedMonths: currentMonths } });
    }

    getMonthName(month) {
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        
        if (month >= 1 && month <= 12) {
            return months[month - 1];
        }

        return "Invalid month number";
    }

    renderLoadingState() {
        return (!this.state.isLoadingData) ? (<></>) : (
            <div className="margin-top-md">
                <ProgressBar animated now={100} />
            </div>
        )
    }

    canRenderPersonSelect() {
        const eligibleStates = ['STUDENT_SELECT', 'ABSENCE_TYPE_SELECT', 'INSTRUMENT_SELECT',
            'APPOINTMENT_SELECT', 'MONTH_SELECT'];
        return eligibleStates.includes(this.state.currentFormStage);
    }

    canRenderAbsenceTypeSelect() {
        return ['ABSENCE_TYPE_SELECT', 'INSTRUMENT_SELECT', 'APPOINTMENT_SELECT', 'MONTH_SELECT']
            .includes(this.state.currentFormStage);
    }

    canRenderInstrumentSelect() {
        return ['INSTRUMENT_SELECT', 'APPOINTMENT_SELECT'].includes(this.state.currentFormStage);
    }

    canRenderAppointmentSelect() {
        return this.state.isInitialAbsenceTypeSelection && this.state.currentFormStage === "APPOINTMENT_SELECT";
    }

    canRenderMonthSelect() {
        return this.state.isInitialAbsenceTypeSelection && this.state.currentFormStage === "MONTH_SELECT";
    }

    hasAppointmentOnMonth(month) {
        return this.state.submittedAbsenceEvents
            .map(absence => parseInt(new Date(absence['startDate']).getMonth()) + 1)
            .includes(month);
    }

    hasHoldOnMonth(eventOccurrenceId) {
        const event = this.state.studentSchedules.find(schedule => schedule['eventOccurrenceId'] == eventOccurrenceId);
        return this.includesStudentHolds(parseInt(new Date(event['startAt']).getMonth()) + 1);
    }

    includesStudentHolds(month) {
        return this.getHoldsBySelectedPersonId()
            .map(hold => parseInt(hold['invoiceDate'].split('-')[1]))
            .includes(month);
    }

    getHoldsBySelectedPersonId() {
        const { selectedPersonId } = this.state.absenceForm;
        return this.state.submittedMonths.filter(submittedMonth => submittedMonth['personId'] == selectedPersonId);
    }

    getMonthOptions() {
        const currentDate = new Date();
        return [6, 7, 8].filter(month => month > (currentDate.getMonth() + 1));
    }

    canSubmit() {
        const { paginatedScheduleResults, maxSelectableVisitsAllowed } = this.state;
        if (!this.state.absenceForm.holdType) {
            return this.canRenderAppointmentSelect() && 
                paginatedScheduleResults.length > 0 && 
                maxSelectableVisitsAllowed > 0;
        }
        return this.canRenderMonthSelect();
    }

    render() {
        const { 
            isLoading, 
            isLoadingState, 
            isError, 
            absenceForm, 
            statusMessage, 
            isSubmissionSuccessful, 
            validationErrors,
            currentVisitPage,
            paginatedScheduleResults,
            isInitialAbsenceTypeSelection
        } = this.state;
        if (isLoading) { return (<LoadingIndicator />) }
        if (isError) { return (<ErrorMessage />) }
        if (isSubmissionSuccessful) {
            return (
                <Alert className="alert-message" variant={this.getAlertMessageVariant()}>
                    Summer absence request successfully submitted. You may close this form or&nbsp;
                    <a className="submit-another" onClick={() => window.location.reload()}>submit another</a>
                </Alert>
            )
        }

        return (
            <>
                {statusMessage.length > 0 && (
                    <Alert className="alert-message" variant={this.getAlertMessageVariant()}>
                        An error occurred while processing the form: { statusMessage }
                    </Alert>
                )}
                {validationErrors.length > 0 && (
                    <Alert className="alert-message" variant={this.getAlertMessageVariant()}>
                        The following errors on the form must be corrected:
                        <ul className="margin-top-md">
                            {validationErrors.map((entry, index) => (<li>{entry}</li>))}
                        </ul>
                    </Alert>
                )}
                <Form noValidate className="form-container" onSubmit={(e) => e.preventDefault()}>
                    <Alert variant="info">
                        Note: If you have submitted absences for the next month within seven (7) days of billing, you may receive a refund once that bill is paid instead of a discount.
                    </Alert>
                    <Alert variant="info">
                        Plan Participant must attend at least one month at full tuition cost after utilizing Summer Absences. If Plan Participant requests to discontinue after utilizing Summer Absences and does so prior to making the next complete payment (with no discounts), Plan Participant will forfeit their security deposit to repay the Monthly Tuition Fee Credits and shall be billed for any amount in excess of their security deposit.
                    </Alert>
                    <Form.Group className="margin-top-md">
                        <Form.Label>Email</Form.Label>
                        <Form.Control name="email" type="email" placeholder="Enter email" value={absenceForm.email} onChange={this.handleFormChange} required />
                        <Form.Control.Feedback type="invalid">
                            Please choose a valid email address.
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            Please enter your email address <strong>exactly</strong> as it appears on your Pike13 account
                        </Form.Text>
                        <OMSButton className="margin-top-md margin-left-md margin-right-md oms-control-button" 
                            onClick={() => this.handleStateTransition("STUDENT_SELECT")}>
                            SEARCH
                        </OMSButton>
                    </Form.Group>
                    {(this.canRenderPersonSelect() && 
                        !this.state.isLoadingData && !isLoadingState) && (
                            <Card style={{ 'marginTop': '24px' }}>
                                <Card.Body>
                                    <Card.Title>For which student would you like to submit summer absences for?</Card.Title>
                                    <div className="margin-top-md">
                                        {this.getStudents().map(person => (
                                                <Form.Check className="oms-radio-checkbox"
                                                    type={'radio'}
                                                    key={`${person['personId']}`}
                                                    id={`${person['personId']}`}
                                                    value={`${person['personId']}`}
                                                    name="selectedPersonId"
                                                    label={`${person['name']}`}
                                                    onChange={(event) => this.handleFormAndStageChange(event, "ABSENCE_TYPE_SELECT")}
                                                />
                                            ))
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                    )}
                    {(this.canRenderAbsenceTypeSelect() && !isLoadingState) && (
                            <Card style={{ 'marginTop': '24px' }}>
                                <Card.Body>
                                    <Card.Title>Select what type of absence to submit</Card.Title>
                                    <Card.Subtitle>
                                        Holds may not be placed on individual instruments/ensembles; if you would like to place a hold on lessons, that entire month's services will be cancelled. If you'd like to cancel only certain lessons or ensembles, please utilize our summer absence feature.
                                    </Card.Subtitle>
                                    <div className="margin-top-md">
                                        <Form.Check className="oms-radio-checkbox"
                                            type={'radio'}
                                            value={false}
                                            name="holdType"
                                            label={'Submit Summer Absence For Tuition Credits'}
                                            checked={isInitialAbsenceTypeSelection && this.state.absenceForm.holdType === false}
                                            onChange={(event) => this.handleFormAndStageChange(event, "INSTRUMENT_SELECT")}
                                        />
                                        <Form.Check className="oms-radio-checkbox"
                                            type={'radio'}
                                            value={true}
                                            name="holdType"
                                            label={'Request A Plan Hold'}
                                            checked={isInitialAbsenceTypeSelection && this.state.absenceForm.holdType === true}
                                            onChange={(event) => this.handleFormAndStageChange(event, "MONTH_SELECT")}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                    )}
                    {(this.canRenderInstrumentSelect() && !isLoadingState) && (
                            <Card style={{ 'marginTop': '24px' }}>
                                <Card.Body>
                                    <Card.Title>For which instrument would you like to submit summer absences?</Card.Title>
                                    <Card.Subtitle>After submitting, you may submit for additional instruments.</Card.Subtitle>
                                    <div className="margin-top-md">
                                        {this.state.instrumentResults.map(instrument => (
                                                <Form.Check className="oms-radio-checkbox"
                                                    type={'radio'}
                                                    key={`${instrument['serviceId']}`}
                                                    id={`${instrument['serviceId']}`}
                                                    value={`${instrument['serviceId']}`}
                                                    name="selectedServiceId"
                                                    label={`${instrument['name']}`}
                                                    checked={this.state.absenceForm.selectedServiceId == instrument['serviceId']}
                                                    onChange={(event) => this.handleFormAndStageChange(event, "APPOINTMENT_SELECT")}
                                                />
                                            ))
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                    )}
                    {(this.canRenderAppointmentSelect() && paginatedScheduleResults.length == 0 && !isLoadingState) && (
                        <Card style={{ 'marginTop': '24px' }}>
                            <Card.Body>
                                No events found for this instrument.
                            </Card.Body>
                        </Card>
                    )}
                    {(this.canRenderAppointmentSelect() && paginatedScheduleResults.length > 0 && this.state.maxSelectableVisitsAllowed > 0 && !isLoadingState) && (
                            <Card style={{ 'marginTop': '24px' }}>
                                <Card.Body>
                                    <Card.Title>
                                        <div className="card-title-pagination">
                                            <span>
                                                For which appointment(s) would you like to submit summer absences?
                                            </span>
                                            <Pagination>
                                                {
                                                    Array.from({length: paginatedScheduleResults.length}, (x, i) => i).map(number => (
                                                        <Pagination.Item 
                                                            key={number} 
                                                            active={number == this.currentVisitPage}
                                                            onClick={() => this.setPageNumber(number)}>
                                                            {number + 1}
                                                        </Pagination.Item>
                                                    ))
                                                }
                                            </Pagination>
                                        </div>
                                    </Card.Title>
                                    <Card.Subtitle>
                                        <div className="selected-appointments">
                                            {
                                                absenceForm.selectedAppointments.map(appointment =>(
                                                    <span className="block">
                                                        {`${appointment['serviceName']} | 
                                                        ${appointment['name']} | 
                                                        ${new Date(appointment['startAt']).toLocaleDateString()}
                                                        ${new Date(appointment['startAt']).toLocaleTimeString()} - 
                                                        ${new Date(appointment['endAt']).toLocaleTimeString()}`}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                        <span className="block margin-top-md">
                                            Lesson Credits Remaining For This Instrument: {this.state.maxSelectableVisitsAllowed - 
                                                absenceForm.selectedAppointments.length}
                                        </span>
                                    </Card.Subtitle>
                                    {!this.state.isLoadingPagination && (
                                        <div className="margin-top-md">
                                            {paginatedScheduleResults[currentVisitPage].map(visit => (
                                                    <Form.Check className="margin-top-md margin-bottom-md oms-radio-checkbox-select" 
                                                                type={'checkbox'} 
                                                                key={`${visit['eventOccurrenceId']}`}
                                                                id={`${visit['eventOccurrenceId']}`}>
                                                        <Form.Check.Input type={'checkbox'} 
                                                            value={`${visit['eventOccurrenceId']}`}
                                                            key={`${visit['eventOccurrenceId']}`}
                                                            disabled={this.hasHoldOnMonth(visit['eventOccurrenceId'])}
                                                            checked={this.isEventSelected(visit['eventOccurrenceId'])}
                                                            onChange={() => {this.addOrRemoveSelectedEvent(visit)}}
                                                        />
                                                        <Form.Check.Label>
                                                            {`${visit['serviceName']} | ${visit['name']} | ${new Date(visit['startAt']).toLocaleDateString()} ${new Date(visit['startAt']).toLocaleTimeString()} - ${new Date(visit['endAt']).toLocaleTimeString()}`}
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                ))
                                            }
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                    )}
                    {(this.canRenderMonthSelect() && !isLoadingState) && (
                            <Card style={{ 'marginTop': '24px' }}>
                                <Card.Body>
                                    <Card.Title>
                                        <span>
                                            For which month(s) would you like to submit holds for?
                                        </span>
                                    </Card.Title>
                                    <Card.Subtitle>A spot may be held for a fee of $35/student per month.</Card.Subtitle>
                                    {this.getHoldsBySelectedPersonId().length > 0 && (  
                                            <span className="margin-top-md">
                                                <br />Note: Disabled checkboxes indicate submitted holds
                                            </span>
                                    )}
                                    <div className="margin-top-md">
                                        {this.getMonthOptions().map(month => (
                                                <Form.Check className="margin-top-md margin-bottom-md oms-radio-checkbox-select" 
                                                            type={'checkbox'} 
                                                            id={`${month}`}
                                                            key={`${month}`}>
                                                    <Form.Check.Input type={'checkbox'} 
                                                        value={`${month}`}
                                                        disabled={this.includesStudentHolds(month) || 
                                                                    this.hasAppointmentOnMonth(month)}
                                                        checked={this.isMonthSelected(month)}
                                                        onChange={() => {this.addOrRemoveSelectedMonth(month)}}
                                                    />
                                                    <Form.Check.Label>{`${this.getMonthName(month)}`}</Form.Check.Label>
                                                </Form.Check>
                                            ))
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                    )}
                    {(this.canSubmit()) && (
                        <OMSButton className="margin-top-md margin-left-md margin-right-md oms-control-button" 
                            onClick={(event) => this.handleSubmit(event)}>
                            SUBMIT
                        </OMSButton>
                    )}
                    {this.renderLoadingState()}
                </Form>
            </>
        )
    }

}

export default SummerAbsenceForm;
