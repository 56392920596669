import React from 'react';

import './OMSButton.css';
import './../../index.css';

class OMSButton extends React.Component {

  render() {
      return(
        <div className={this.props.className} onClick={this.props.onClick}>
          <div className="button-container" id="button-variables" aria-disabled="false">
            <div className="button-body" aria-disabled="false">
  		        <div className="button-content expand inline-block">
                <strong>{this.props.children}</strong>
              </div>
  	        </div>
          </div>
        </div>
      )
  }

}

export default OMSButton;
