import React from 'react';

import Spinner from "react-bootstrap/Spinner";

import './LoadingIndicator.css';
import './../../index.css';

class LoadingIndicator extends React.Component {

    render() {
        return (
            <div className="expand loading-container">
                <div className="loading-indicator">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </div>
        )
    }

}

export default LoadingIndicator;