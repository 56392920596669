import React from 'react';

import "./ErrorMessage.css";

class ErrorMessage extends React.Component {

    render() {
        return (
            <strong className="block expand margin-top-md error-message">An error has occurred. Please try again later.</strong>
        )
    }

}

export default ErrorMessage;